<template>
      <v-card class="mt-10">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Category</strong></label>
                            <v-text-field
                            v-model="category"
                            :rules="categoryRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Description</strong></label>
                            <v-textarea
                            v-model="description"
                            :rules="descriptionRules"
                            required
                            outlined
                            dense
                            ></v-textarea>
                        </v-col>
                        <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn
                                color="blue darken-1"
                                dark
                                @click="goBack"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                            :disabled="!valid || loading"
                            :color="`${zamuYellow3}`"
                            class="mr-4 zamu-sub-heading "
                            @click="save"
                            dark
                            >
                            Save category
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>
                    </v-row>
                </v-form>
              </v-container>
            </v-card-text>
       </v-card> 
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'NewCategory',
    mixins: [coreMixin, colorMixin],

    data (){
      return {
        formTitle: 'Add New Blog Category',
        valid: false,
        loading: false,
        category: '',
        description: '',
        notification: '',
        actionClass: '',
      };
    },
    methods: {
        async save () {
            const formvalid = this.$refs.form.validate();
            if(!formvalid) {
                return;
            }

              this.loading = true;

               const payload = {
                    category: this.category,
                    description: this.description,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'blog-categories/new',
                };
                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.status === 200 ? 'Blog category saved successful' : res.data.message;
                    this.actionClass = res.status === 200 ? 'success' : 'error';
                    if(res.status === 200) {
                      this.goBack();
                    }

                } catch (error) {
                    this.notification = 'Blog category failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }


        },
    }

}
</script>

<style>

</style>